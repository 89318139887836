import { combineReducers } from 'redux';

import loading from './loadingReducer';
import setting from './settingReducer';

const reducer = combineReducers({
  loading,
  setting,
});

export default reducer;
