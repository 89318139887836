export const LOCALE_ZH_CN_KNOWN = 'LOCALE_ZH_CN_KNOWN';
export const LOCALE_ZH_OTHER_KNOWN = 'LOCALE_ZH_OTHER_KNOWN';
export const LOCALE_OTHER_KNOWN = 'LOCALE_OTHER_KNOWN';

export const zhCnKnown = (): object => ({
  type: LOCALE_ZH_CN_KNOWN,
});

export const zhOtherKnown = (): object => ({
  type: LOCALE_ZH_OTHER_KNOWN,
});

export const otherKnown = (): object => ({
  type: LOCALE_OTHER_KNOWN,
});

export const updateLocale = (locale: string) => (
  dispatch: Function
): void => {};
