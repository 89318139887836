import {
  LOCALE_ZH_CN_KNOWN,
  LOCALE_ZH_OTHER_KNOWN,
  LOCALE_OTHER_KNOWN,
} from '../actions/settingActions';
import locale from '../utils/locale';

const InitialState = {
  locale: locale.enUs,
};

const reducer = (state = InitialState, action: any): object => {
  switch (action.type) {
    case LOCALE_ZH_CN_KNOWN:
      return {
        ...state,
        locale: locale.zhCh,
      };
    case LOCALE_ZH_OTHER_KNOWN:
      return {
        ...state,
        locale: locale.zhCh,
      };
    case LOCALE_OTHER_KNOWN:
      return {
        ...state,
        locale: locale.enUs,
      };
    default:
      return state;
  }
};

export default reducer;
