import { LOADING_START, LOADING_END } from '../actions/loadingActions';

const InitialState = {
  isLoading: false,
};

export default function reducer(state = InitialState, action: any) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_END:
      return {
        ...state,
        isLoading: false,
      };
    default:
  }
  return state;
}
