export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';

export const loadingStart = (): object => ({
  type: LOADING_START,
});

export const loadingEnd = (): object => ({
  type: LOADING_END,
});
