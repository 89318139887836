/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
/* eslint react/prop-types: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
/* eslint react/react-in-jsx-scope: 0 */
/* eslint @typescript-eslint/explicit-function-return-type: 0 */
/* eslint import/prefer-default-export: 0 */

import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducers from './src/reducers';
import { theme } from './src/styles/styles';

const store = createStore(reducers, applyMiddleware(thunk));

export const wrapPageElement = ({ element }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {element}
    </ThemeProvider>
  </Provider>
);
