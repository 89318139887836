import { createMuiTheme } from '@material-ui/core/styles';

export const Color = {
  primary: '#20232a',
  primaryDark: '#16181d',
  primaryLight: '#282c34',
  grey: '#f5f5f7',
  cyan300: '#4dd0e1',
  cyan500: '#00BCD4',
  cyan700: '#0097A7',
  cyan900: '#006064',
  purple100: '#E1BEE7',
  purple900: '#4A148C',
  teal100: '#B2DFDB',
  teal900: '#004D40',
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: Color.primary,
      light: Color.primaryLight,
      dark: Color.primaryDark,
      contrastText: Color.grey,
    },
    secondary: {
      light: Color.cyan500,
      main: Color.cyan700,
      dark: Color.cyan900,
      contrastText: Color.grey,
    },
    background: {
      default: Color.grey,
    },
  },
});
