const locale: any = {
  zhCn: {
    header: {
      siteName: '超级火车票12306购票',
    },
  },
  enUs: {
    header: {
      siteName: 'Super Train Ticket 12306',
    },
  },
};

export default locale;
